const colors = [
  'text-green-500',
  'text-light-green-500',
  'text-lime-500',
  'text-yellow-500',
  'text-orange-500',
  'text-deep-orange-500',
  'text-red-500',
];

export interface IHamsterCardResults {
  price: string;
  income: string;
  payback: string | null; //number
}

export const getPaybackTextColor = (
  name: string,
  results: Record<string, IHamsterCardResults>,
): string | undefined => {
  const result = results[name];

  if (!result || !result.payback) {
    return undefined;
  }

  const paybackValue = parseFloat(result.payback);
  if (isNaN(paybackValue)) {
    return undefined;
  }

  const paybacks = Object.values(results)
    .map((r) => (r.payback !== null ? parseFloat(r.payback) : null))
    .filter((p) => p !== null) as number[];

  paybacks.sort((a, b) => a - b);

  const index = paybacks.indexOf(paybackValue);

  const normalizedIndex = Math.floor((index * (colors.length - 1)) / (paybacks.length - 1));

  return colors[normalizedIndex];
};
