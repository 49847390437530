import React from 'react';

import logo from '@assets/logo.png';
import routes from '@config/routes';
import { NavLink } from 'react-router-dom';

const Logo = () => {
  return (
    <NavLink to={routes.HOME}>
      <div>
        <img src={logo} alt="logo" className="w-[80px]" />
      </div>
    </NavLink>
  );
};

export default React.memo(Logo);
