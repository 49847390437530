import React from 'react';

import aframeJpg from '@assets/a-frame.jpg';
import instagramPNG from '@assets/instagram.png';
import { InfoCard } from '@components/common';
import links from '@config/links';

const RelaxZone = () => {
  return (
    <div>
      <div className="grid grid-cols-1 3xl:grid-cols-2 gap-6">
        <InfoCard
          title="Aframe Samoed"
          description="Насладитесь свежим воздухом и отдохните от городской суеты в нашей базе отдыха. Здесь вы найдете все условия для комфортного и приятного времяпрепровождения"
          icon={aframeJpg}
          links={[
            {
              url: links.INSTAGRAM_AFRAME_SAMOED,
              icon: instagramPNG,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default React.memo(RelaxZone);
