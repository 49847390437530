import React from 'react';

import Tabs, { ITab } from '@components/common/Tabs';
import { Page } from '@components/layout';
import routes from '@config/routes';
import { FoundersTab, PartnersTab, TargetTab } from 'components/about-us';

const tabs: ITab[] = [
  {
    label: 'Цель',
    value: routes.TARGET_TAB,
    content: <TargetTab />,
  },
  {
    label: 'Основатели',
    value: routes.FOUNDERS_TAB,
    content: <FoundersTab />,
  },
  {
    label: 'Партнёры',
    value: routes.PARTNERS_TAB,
    content: <PartnersTab />,
  },
];

const AboutUsPage = () => {
  return (
    <Page>
      <div className="lg:pl-[15%] xl:pl-[12%] 3xl:pl-[10%] min-h-[600px]">
        <Tabs tabs={tabs} defaultTab={routes.TARGET_TAB} tabClassName="lg:text-3xl xl:text-5xl" />
      </div>
    </Page>
  );
};

export default React.memo(AboutUsPage);
