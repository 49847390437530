import React from 'react';

import { Typography } from '@material-tailwind/react';

const HamsterCardsHeading = () => {
  return (
    <div className="flex gap-3 border-b border-amber-500 px-0.5 py-1.5">
      <div className="w-[30%]">
        <Typography variant="small" className="font-semibold text-center text-xs">
          Карточка
        </Typography>
      </div>
      <div className="w-[20%]">
        <Typography variant="small" className="font-semibold text-center text-xs">
          Цена
        </Typography>
      </div>
      <div className="w-[25%]">
        <Typography variant="small" className="font-semibold text-center text-xs">
          Прибыль в час
        </Typography>
      </div>
      <div className="w-[25%]">
        <Typography variant="small" className="font-semibold text-center text-xs">
          Окупаемость, дней
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(HamsterCardsHeading);
