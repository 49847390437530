export default {
  HOME: '/',
  ABOUT_US: '/about-us',
  CONNECTION: '/connection',
  VACANCIES: '/vacancies',
  TARGET_TAB: '/target-tab',
  FOUNDERS_TAB: '/founders-tab',
  PARTNERS_TAB: '/partners-tab',
  HAMSTER_COMBAT: '/hamster-combat',
};
