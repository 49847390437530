import React from 'react';

import HamsterCard from '@components/hamster-combat/HamsterCard';
import HamsterCardsHeading from '@components/hamster-combat/HamsterCardsHeading';
import { IHamsterCard, IHamsterCardResults } from '@interfaces/hamster-card';

import { getPaybackTextColor } from '../../utils/hamster-combat';

type PropsType = {
  cards: IHamsterCard[];
  results: Record<string, IHamsterCardResults>;
  onChange: (name: string, results: IHamsterCardResults) => void;
};

const HamsterCardsList: React.FC<PropsType> = ({ cards, results, onChange }) => {
  return (
    <div className="flex flex-col">
      <HamsterCardsHeading />
      {cards.map((card) => (
        <HamsterCard
          key={card.name}
          card={card}
          results={results[card.name]}
          paybackColor={getPaybackTextColor(card.name, results)}
          onChange={(results) => onChange(card.name, results)}
        />
      ))}
    </div>
  );
};

export default React.memo(HamsterCardsList);
