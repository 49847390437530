import React from 'react';

import { Typography } from '@material-tailwind/react';

import founderImg from '../../assets/founder.jpg';

interface IFounder {
  avatar: string;
  name: string;
  description: string;
}

const founders: IFounder[] = [
  {
    avatar: founderImg,
    name: 'Максим Шайков',
    description:
      'Автор YouTube канала MAKS BAKS и Telegram каналов: MAKS BAKS, MBaks Crypto, MBaks Trading. В криптоиндустрии с 2016 года. CEO продуктов Mabos',
  },
];

const FoundersTab = () => {
  return (
    <div className="flex justify-center lg:max-w-[84%]">
      {founders.map((founder) => (
        <div key={founder.name} className="lg:flex">
          <div className="flex justify-center lg:mr-8">
            <img
              className="w-[230px] h-[287px] object-cover object-[30%_center] rounded-2xl"
              src={founder.avatar}
              alt="founder"
            />
          </div>
          <div className="mt-6 lg:mt-8 text-center lg:max-w-[30%] lg:text-left">
            <Typography className="mb-6 lg:mb-7 text-2xl lg:text-3xl font-bold">
              {founder.name}
            </Typography>
            <Typography className="text-sm font-medium">{founder.description}</Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(FoundersTab);
