import React from 'react';

import exchangePNG from '@assets/exchange.png';
import ledgerPNG from '@assets/ledger.png';
import mabosPNG from '@assets/mabos.png';
import mediaPNG from '@assets/media.png';
import treePNG from '@assets/tree.png';
import { CardsPage } from '@components/cards';
import { ICardTab } from '@components/cards/CardTabs';
import Title from '@components/common/Title';
import { Exchanges, Mabos, Media, RelaxZone, Wallets } from '@components/home';
import { Page } from '@components/layout';

const tabs: ICardTab[] = [
  {
    label: 'Надежные биржи',
    value: 'exchanges',
    icon: <img className="h-[80px]" src={exchangePNG} alt="Exchange" />,
    content: <Exchanges />,
  },
  {
    label: 'Холодные кошельки',
    value: 'wallets',
    icon: <img className="h-[80px]" src={ledgerPNG} alt="Wallet" />,
    content: <Wallets />,
  },
  {
    label: 'Медиа',
    value: 'media',
    icon: <img className="h-[80px]" src={mediaPNG} alt="Media" />,
    content: <Media size="normal" />,
  },
  {
    label: 'Relax Zone',
    value: 'relax-zone',
    icon: <img className="h-[80px]" src={treePNG} alt="Tree" />,
    content: <RelaxZone />,
  },
  {
    label: 'Mabos',
    value: 'mabos',
    icon: <img className="h-[80px]" src={mabosPNG} alt="Mabos" />,
    content: <Mabos />,
  },
];

const HomePage: React.FC = () => {
  return (
    <Page>
      <CardsPage
        title={
          <Title>
            Экосистема
            <h1 className="text-white">
              <span className="text-amber-500">M</span>AKS
              <span className="text-amber-500"> BAKS</span>
            </h1>
          </Title>
        }
        tabs={tabs}
      />
    </Page>
  );
};

export default React.memo(HomePage);
