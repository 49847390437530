import React from 'react';

import cn from 'classnames';
import { motion } from 'framer-motion';

type PropsType = {
  className?: string;
};

const Page: React.FC<React.PropsWithChildren<PropsType>> = ({ children, className }) => {
  return (
    <motion.div
      className={cn('grow flex flex-col w-full', className)}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
          delay: 0.3,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.3,
        },
      }}
      onAnimationStart={() => {
        document.body.classList.add('no-scroll');
      }}
      onAnimationComplete={() => {
        document.body.classList.remove('no-scroll');
      }}
    >
      {children}
    </motion.div>
  );
};

export default React.memo(Page);
