import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { IconButton } from '@material-tailwind/react';

type PropsType = {
  onPrev?: () => void;
  onNext?: () => void;
};

const CardsNavigation: React.FC<PropsType> = ({ onPrev, onNext }) => {
  return (
    <div className="flex justify-end py-3 pr-6">
      <div className="flex gap-3">
        <IconButton color="amber" onClick={onPrev}>
          <ChevronLeftIcon className="text-gray-800 w-6" />
        </IconButton>
        <IconButton color="amber" onClick={onNext}>
          <ChevronRightIcon className="text-gray-800 w-6" />
        </IconButton>
      </div>
    </div>
  );
};

export default React.memo(CardsNavigation);
