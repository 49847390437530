import React from 'react';

import binanceIcon from '@assets/binance.png';
import bingXIcon from '@assets/bingx.png';
import byBitIcon from '@assets/byBit.png';
import mexcIcon from '@assets/mexc.png';
import okxIcon from '@assets/okx.png';
import { IconLink } from '@components/common';
import links from '@config/links';
import { Typography } from '@material-tailwind/react';

const Exchanges = () => {
  return (
    <div>
      <Typography className="font-medium">
        Рейтинг лучших бирж с бонусами и скидками на торговую комиссию:
      </Typography>
      <div className="flex gap-6 mt-4 justify-between md:justify-start flex-wrap md:flex-nowrap">
        <IconLink url={links.BYBIT} title="ByBit" icon={byBitIcon} size="medium" />
        <IconLink url={links.BING_X} title="BingX" icon={bingXIcon} size="medium" />
        <IconLink url={links.BINANCE} title="Binance" icon={binanceIcon} size="medium" />
        <IconLink url={links.OKX} title="OKX" icon={okxIcon} size="medium" />
        <IconLink
          url={links.MEXC}
          title="MEXC"
          icon={mexcIcon}
          size="medium"
          iconClassName="bg-[#fff] p-2"
        />
      </div>
    </div>
  );
};

export default React.memo(Exchanges);
