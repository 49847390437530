import React from 'react';

import MainLayout from '@components/layout/MainLayout';
import routes from '@config/routes';
import {
  AboutUsPage,
  ConnectionPage,
  HamsterCombat,
  HomePage,
  NotFoundPage,
  VacanciesPage,
} from '@pages/index';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';

const Router: React.FC = () => {
  const location = useLocation();

  return (
    <MainLayout>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route index element={<HomePage />} />
          <Route path={routes.ABOUT_US} element={<AboutUsPage />} />
          <Route path={routes.CONNECTION} element={<ConnectionPage />} />
          <Route path={routes.VACANCIES} element={<VacanciesPage />} />
          <Route path={routes.HAMSTER_COMBAT} element={<HamsterCombat />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AnimatePresence>
    </MainLayout>
  );
};

export default React.memo(Router);
