import {
  ButtonStyleTypes,
  TypographyStylesType,
  CardStylesType,
  InputStylesType,
  SelectStylesType,
  AlertStylesType,
  DialogStylesType,
  CheckboxStylesType,
  ChipStylesType,
  PopoverStylesType,
  IconButtonStyleTypes,
} from '@material-tailwind/react';

export const theme = {
  card: {
    defaultProps: {
      color: 'gray',
      variant: 'gradient',
    },
  } as CardStylesType,
  typography: {
    defaultProps: {
      color: 'white',
    },
    styles: {
      variants: {
        small: {
          fontWeight: 'font-normal',
        },
        paragraph: {
          fontWeight: 'font-normal',
        },
      },
    },
  } as TypographyStylesType,
  button: {
    defaultProps: {
      color: 'amber',
      variant: 'gradient',
    },
    styles: {
      variants: {
        gradient: {
          amber: {
            color: 'text-gray-800',
          },
          gray: {
            color: 'text-[#fff]',
          },
        },
        outlined: {
          gray: {
            border: '!border border-gray-600 rounded-[7px]',
            color: 'text-white',
            hover: 'hover:opacity-100',
            focus: 'focus:border-amber-500 border-2',
            active: 'active:opacity-[0.85]',
          },
        },
      },
    },
  } as ButtonStyleTypes,
  input: {
    defaultProps: {
      color: 'amber',
      size: 'lg',
    },
    styles: {
      base: {
        input: {
          color: 'text-white',
          fontWeight: 'font-semibold',
          disabled: 'disabled:bg-gray-900 disabled:border-0',
        },
        label: {
          color: 'text-white',
          fontWeight: 'font-semibold',
          disabled:
            'peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-gray-700',
        },
      },
      variants: {
        outlined: {
          base: {
            input: {
              borderColor: 'placeholder-shown:border-gray-600 placeholder-shown:border-t-gray-600',
            },
            label: {
              fontSize: 'peer-placeholder-shown:text-base',
              floated: {
                fontSize: 'text-[12px] peer-focus:text-[12px]',
              },
            },
          },
          colors: {
            input: {
              amber: {
                borderColor: 'border-gray-600',
              },
            },
            label: {
              amber: {
                color: 'text-white peer-focus:text-amber-500',
                before: 'before:border-gray-600 peer-focus:before:!border-amber-500',
                after: 'after:border-gray-600 peer-focus:after:!border-amber-500',
              },
            },
          },
          sizes: {
            lg: {
              input: {
                fontSize: 'text-base',
                pt: 'pt-2',
                pb: 'pb-2',
              },
              label: {
                lineHeight: 'peer-placeholder-shown:leading-[3.6]',
              },
            },
          },
        },
      },
    },
  } as InputStylesType,
  select: {
    defaultProps: {
      color: 'amber',
      size: 'lg',
    },
    styles: {
      base: {
        container: {
          minWidth: 'min-w-[50px]',
        },
        select: {
          color: 'text-white',
          disabled: 'disabled:bg-gray-900 disabled:border-0',
        },
        label: {
          fontSize: 'text-base',
          fontWeight: 'font-semibold',
        },
        arrow: {
          initial: {
            color: 'text-white',
          },
        },
        menu: {
          bg: 'bg-gray-900',
          border: 'border border-border-nav',
          boxShadow: 'shadow-lg shadow-gray-900/10',
          color: 'text-white',
          fontSize: 'text-base',
          fontWeight: 'font-semibold',
          width: 'w-full min-w-[200px]',
        },
        option: {
          initial: {
            background:
              'hover:from-gray-900 hover:to-blue-gray-700 hover:bg-gradient-to-tr focus:from-gray-900 focus:to-blue-gray-700 focus:bg-gradient-to-tr',
            opacity: 'hover:bg-opacity-100 focus:bg-opacity-100',
            color: 'hover:text-white focus:text-white',
          },
          active: {
            bg: 'bg-opacity-100 from-gray-900 to-blue-gray-700 bg-gradient-to-tr',
            color: 'text-[#fff]',
          },
        },
      },
      variants: {
        outlined: {
          sizes: {
            lg: {
              select: {
                fontWeight: 'font-semibold',
                fontSize: 'text-base',
                px: 'px-2',
                py: 'py-2',
              },
              label: {
                states: {
                  close: {
                    lineHeight: 'leading-[3.8]',
                  },
                },
              },
            },
          },
          colors: {
            select: {
              amber: {
                close: {
                  borderColor: 'border-gray-600',
                },
                withValue: {
                  borderColor: 'border-gray-600',
                },
              },
            },
            label: {
              amber: {
                close: {
                  color: 'text-white',
                  before: 'before:border-transparent',
                  after: 'after:border-transparent',
                },
                withValue: {
                  color: 'text-white',
                  before: 'before:border-gray-600',
                  after: 'after:border-gray-600',
                },
              },
            },
          },
          states: {
            close: {
              label: {
                fontSize: 'text-base',
                disabled: 'peer-disabled:text-gray-700',
              },
            },
            open: {
              label: {
                fontSize: 'text-[12px]',
                fontWeight: 'font-semibold',
              },
            },
            withValue: {
              label: {
                fontSize: 'text-[12px]',
                fontWeight: 'font-semibold',
              },
            },
          },
        },
      },
    },
  } as SelectStylesType,
  alert: {
    styles: {
      base: {
        alert: {
          fontSize: 'text-sm',
          fontWeight: 'font-medium',
        },
      },
      variants: {
        outlined: {
          gray: {
            border: 'border border-gray-600',
            color: 'text-white',
          },
        },
      },
    },
  } as AlertStylesType,
  dialog: {
    defaultProps: {
      size: 'xs',
    },
    styles: {
      base: {
        container: {
          bg: 'from-gray-800 to-blue-gray-800 bg-gradient-to-tr',
          color: 'text-white',
          fontWeight: 'font-semibold',
        },
      },
    },
  } as DialogStylesType,
  checkbox: {
    styles: {
      base: {
        label: {
          color: 'text-white text-sm',
          fontWeight: 'font-semibold',
        },
        icon: {
          color: 'text-gray-900',
        },
      },
    },
  } as CheckboxStylesType,
  chip: {
    styles: {
      variants: {
        outlined: {
          gray: {
            border: 'border border-gray-600',
            color: 'text-white',
          },
        },
      },
    },
  } as ChipStylesType,
  popover: {
    styles: {
      base: {
        bg: 'bg-gray-900',
        p: 'p-4',
        border: 'border border-border-nav',
        boxShadow: 'shadow-lg shadow-gray-900/10',
        borderRadius: 'rounded-lg',
        fontFamily: 'font-sans',
        color: 'text-white',
      },
    },
  } as PopoverStylesType,
  iconButton: {
    styles: {
      variants: {
        outlined: {
          gray: {
            border: 'border border-gray-600',
            color: 'text-white',
            hover: 'hover:opacity-75',
            focus: 'focus:ring focus:ring-gray-500',
            active: 'active:opacity-[0.85]',
          },
        },
      },
    },
  } as IconButtonStyleTypes,
};
