import React, { useState } from 'react';

import Card from '@components/cards/Card';
import CardsNavigation from '@components/cards/CardsNavigation';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react';
import cn from 'classnames';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { useXxl } from '../../hooks/responsive/useXxl';

export interface ICardTab {
  label: string;
  value: string;
  icon: React.ReactNode;
  content: React.ReactNode;
}

type PropsType = {
  tabs?: ICardTab[];
  className?: string;
  onScrolled?: (isScrolled: boolean) => void;
};

const CardTabs: React.FC<PropsType> = ({ className, onScrolled, tabs = [] }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [swiperRef, setSwiperRef] = useState<null | SwiperClass>(null);
  const isXxl = useXxl();

  const handleSlideChange = (swiper: SwiperClass) => {
    if (onScrolled) {
      onScrolled(swiper.activeIndex !== 0);
    }
  };

  return (
    <div className={cn('card-tabs', className)}>
      <Tabs value={activeTab}>
        <TabsHeader
          className="bg-transparent"
          indicatorProps={{
            className:
              'bg-gradient-to-tr from-amber-600 to-amber-400 shadow-md shadow-amber-500/20 rounded-lg',
          }}
        >
          <Swiper
            spaceBetween={20}
            slidesPerView={isXxl ? tabs.length : 'auto'}
            resistanceRatio={0.25}
            onSwiper={setSwiperRef}
            onSlideChange={handleSlideChange}
          >
            {tabs.map(({ label, value, icon }) => (
              <SwiperSlide key={value}>
                <Tab
                  value={value}
                  onClick={() => setActiveTab(value)}
                  className="bg-gradient-to-tr p-0 from-gray-900 to-gray-800 shadow-gray-900/20 shadow-md rounded-lg"
                >
                  <Card label={label} icon={icon} isActive={activeTab === value} width={200} />
                </Tab>
              </SwiperSlide>
            ))}
          </Swiper>
        </TabsHeader>
        {!swiperRef?.isLocked && (
          <CardsNavigation
            onNext={() => swiperRef?.slideNext()}
            onPrev={() => swiperRef?.slidePrev()}
          />
        )}
        <TabsBody
          className={cn(swiperRef?.isLocked && 'mt-6')}
          animate={{
            initial: { opacity: 0, top: 'auto', left: 'auto', right: 'auto' },
            mount: {
              opacity: 1,
              top: 'auto',
              left: 'auto',
              right: 'auto',
              transition: {
                duration: 0.3,
                delay: 0.3,
              },
            },
            unmount: {
              opacity: 0,
              top: 'auto',
              left: 'auto',
              right: 'auto',
              transition: {
                duration: 0.3,
              },
            },
          }}
        >
          {tabs.map(({ value, content }) => (
            <TabPanel
              key={value}
              value={value}
              className="text-white font-medium text-base !p-0 !md:p-4"
            >
              {content}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default React.memo(CardTabs);
