import React from 'react';

import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import cn from 'classnames';

type PropsType = {
  label: string;
  icon: any;
  isActive?: boolean;
  width?: number;
};

const Card: React.FC<PropsType> = ({ label, isActive, icon, width }) => {
  return (
    <div
      className="py-6 group w-full"
      style={{
        width,
      }}
    >
      <p
        className={cn(
          'transition-colors text-white font-semibold text-base md:text-2xl md:h-[96px] flex items-center justify-center',
          isActive && '!text-gray-800',
        )}
      >
        {label}
      </p>
      <div className="w-full flex justify-center mt-[50px] mb-[20px]">{icon}</div>
      <div className="w-full flex justify-center">
        <ArrowLongRightIcon className="text-white w-[80px] group-hover:translate-x-3 transition-transform" />
      </div>
    </div>
  );
};

export default React.memo(Card);
