import React from 'react';

import binanceIcon from '@assets/binance.png';
import bingXIcon from '@assets/bingx.png';
import byBitIcon from '@assets/byBit.png';
import okxIcon from '@assets/okx.png';
import { IconLink } from '@components/common';
import links from '@config/links';
import { Typography } from '@material-tailwind/react';

interface IExchange {
  icon: any;
  title: string;
  link: string;
}

const exchanges: IExchange[] = [
  {
    icon: byBitIcon,
    title: 'ByBit',
    link: links.BYBIT,
  },
  {
    icon: bingXIcon,
    title: 'BingX',
    link: links.BING_X,
  },
  {
    icon: okxIcon,
    title: 'OKX',
    link: links.OKX,
  },
  {
    icon: binanceIcon,
    title: 'Binance',
    link: links.BINANCE,
  },
];

const HamsterListing = () => {
  return (
    <div className="mt-12">
      <Typography variant="h3" className="font-semibold text-center">
        Листинг уже скоро, пройди регистрацию на бирже
      </Typography>
      <div className="flex justify-center">
        <div className="grid grid-cols-4 mt-10 gap-3 lg:gap-10">
          {exchanges.map((partner) => (
            <IconLink
              key={partner.link}
              icon={partner.icon}
              title={partner.title}
              url={partner.link}
              size="medium"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(HamsterListing);
