import React from 'react';

import instagramProfilePNG from '@assets/instagram-profile.jpg';
import instagramPNG from '@assets/instagram.png';
import mbCryptoJPG from '@assets/mb-crypto.jpg';
import mbTradingJPG from '@assets/mb-trading.jpg';
import mbJPG from '@assets/mb.jpg';
import telegramPNG from '@assets/telegram.png';
import vkPNG from '@assets/vk.png';
import youtubePNG from '@assets/youtube.png';
import { IconLink } from '@components/common';
import Tabs, { ITab } from '@components/common/Tabs';
import MediaSection from '@components/home/MediaSection';
import MediaTabButton from '@components/home/MediaTabButton';
import links from '@config/links';
import { Typography } from '@material-tailwind/react';
import cn from 'classnames';

enum TABS {
  TELEGRAM = 'TELEGRAM',
  YOU_TUBE = 'YOU_TUBE',
  SOCIAL = 'SOCIAL',
}

type PropsType = {
  size: 'normal' | 'medium';
};

const Media: React.FC<PropsType> = ({ size }) => {
  const tabs: ITab[] = [
    {
      label: <MediaTabButton title="Telegram" icon={telegramPNG} className="sm:w-[150px]" />,
      value: TABS.TELEGRAM,
      content: (
        <MediaSection>
          <IconLink
            url={links.TELEGRAM_INVEST}
            title="Новости, инвестиции, основная важная информация"
            icon={mbJPG}
            subIcon={telegramPNG}
            size={size}
            asRowOnMobile
          />
          <IconLink
            url={links.TELEGRAM_TRADING}
            title="Сделки на трейдинге криптовалют и лайв контент"
            icon={mbCryptoJPG}
            subIcon={telegramPNG}
            size={size}
            asRowOnMobile
          />
          <IconLink
            url={links.TELEGRAM_EDUCATION}
            title="Бесплатное базовое обучение трейдингу и не только"
            icon={mbTradingJPG}
            subIcon={telegramPNG}
            size={size}
            asRowOnMobile
          />
        </MediaSection>
      ),
    },
    {
      label: <MediaTabButton title="YouTube" icon={youtubePNG} className="sm:w-[150px]" />,
      value: TABS.YOU_TUBE,
      content: (
        <MediaSection>
          <IconLink
            url={links.YOU_TUBE_MAIN}
            title="Основной YouTube канал"
            icon={mbJPG}
            subIcon={youtubePNG}
            size={size}
            asRowOnMobile
          />
          <IconLink
            url={links.YOU_TUBE_SHORTS}
            title="YouTube канал с короткими, развлекательными и полезными видео"
            icon={mbCryptoJPG}
            subIcon={youtubePNG}
            size={size}
            asRowOnMobile
          />
          <IconLink
            url={links.YOU_TUBE_EDUCATION}
            title="YouTube канал с обучающими видео"
            icon={mbTradingJPG}
            subIcon={youtubePNG}
            size={size}
            asRowOnMobile
          />
        </MediaSection>
      ),
    },
    {
      label: (
        <MediaTabButton title="Социальные сети" icon={instagramPNG} className="sm:w-[220px]" />
      ),
      value: TABS.SOCIAL,
      content: (
        <MediaSection>
          <IconLink
            url={links.INSTAGRAM}
            title="Лайв контент в Instagram"
            icon={instagramProfilePNG}
            size={size}
            asRowOnMobile
          />
          <IconLink url={links.VK} title="Группа в ВК" icon={vkPNG} size={size} asRowOnMobile />
        </MediaSection>
      ),
    },
  ];

  return (
    <div>
      <Typography className={cn('font-medium', size === 'normal' ? 'mb-3' : 'mb-6')}>
        В наших медиа-ресурсах Вы найдете информацию по трейдингу, инвестированию в криптовалюты и
        фондовый рынок, бесплатное обучение и просто развлекательный контент
      </Typography>
      <Tabs
        tabs={tabs}
        defaultTab={TABS.TELEGRAM}
        headerClassName="items-center justify-center md:items-start md:justify-start w-full md:w-1/3"
        tabPanelClassName="min-h-[50px] py-0"
        bodyClassName="!mt-4"
      />
    </div>
  );
};

export default React.memo(Media);
