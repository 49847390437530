import React from 'react';

import safepalIcon from '@assets/safepal.png';
import tangemIcon from '@assets/tangem.png';
import trezorIcon from '@assets/trezor.png';
import { IconLink } from '@components/common';
import links from '@config/links';
import { Typography } from '@material-tailwind/react';

const Wallets = () => {
  return (
    <div>
      <Typography className="font-medium">
        Биржу используют для покупки и продажи криптовалют. Холодный кошелек для хранения. Рейтинг
        лучших холодных кошельков:
      </Typography>
      <div className="flex gap-6 mt-4">
        <IconLink url={links.TANGEM} title="Tangem" icon={tangemIcon} size="medium" />
        <IconLink url={links.TREZOR} title="Trezor" icon={trezorIcon} size="medium" />
        <IconLink url={links.SAFEPAL} title="Safepal" icon={safepalIcon} size="medium" />
      </div>
    </div>
  );
};

export default React.memo(Wallets);
