import React, { useEffect, useState } from 'react';

import {
  Tabs as MaterialTabs,
  Tab,
  TabPanel,
  TabsBody,
  TabsHeader,
} from '@material-tailwind/react';
import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

export interface ITab {
  label: React.ReactNode;
  value: string;
  icon?: any;
  content: React.ReactNode;
}

type PropsType = {
  defaultTab?: string;
  tabs: ITab[];
  className?: string;
  tabClassName?: string;
  tabPanelClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  useLocationAsState?: boolean;
  orientation?: 'horizontal' | 'vertical';
  rightHeaderElement?: React.ReactNode;
  isTabsActive?: boolean;
};

export type TabPropsType = {
  isActive?: boolean;
};

const Tabs: React.FC<PropsType> = ({
  tabs,
  defaultTab,
  className,
  tabClassName,
  headerClassName,
  tabPanelClassName,
  bodyClassName,
  useLocationAsState,
  orientation,
  rightHeaderElement,
  isTabsActive = true,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>('');

  const getInitialTab = () => {
    if (useLocationAsState) {
      const isValid = tabs.some((t) => t.value === location.pathname);

      if (isValid) {
        return location.pathname;
      }
    }

    return defaultTab || tabs[0]?.value || '';
  };

  useEffect(() => {
    const initialTab = getInitialTab();

    setActiveTab(initialTab);

    if (useLocationAsState && location.pathname !== initialTab) {
      navigate(initialTab);
    }
  }, []);

  const handleChange = (value: string) => {
    setActiveTab(value);

    if (useLocationAsState) {
      navigate(value);
    }
  };

  if (!activeTab) {
    return null;
  }

  return (
    <MaterialTabs
      value={activeTab}
      className={cn('w-full overflow-visible', className)}
      orientation={orientation}
    >
      <div className={cn(rightHeaderElement && 'flex justify-between')}>
        <TabsHeader
          className={cn(
            'bg-transparent w-1/3',
            orientation === 'vertical' && 'flex flex-col',
            headerClassName,
          )}
          indicatorProps={{
            className: 'bg-opacity-0 shadow-none',
          }}
        >
          {tabs.map(({ label, value, icon: Icon }) => (
            <Tab
              key={value}
              value={value}
              className={cn(
                'text-white font-semibold text-lg mr-6 md:text-3xl lg:text-5xl md:mr-4 last:mr-0 md:px-6',
                activeTab === value && 'text-amber-500 border-b-2 border-amber-500',
                tabClassName,
              )}
              onClick={() => handleChange(value)}
            >
              <div className="flex items-center lg:mb-3">
                {label}
                {Icon && <Icon className="h-5 w-5 ml-4" />}
              </div>
            </Tab>
          ))}
        </TabsHeader>
        {rightHeaderElement && <div>{rightHeaderElement}</div>}
      </div>
      <TabsBody
        className={cn('mt-3 lg:mt-8', bodyClassName)}
        animate={{
          initial: {
            opacity: 0,
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: 1,
            transition: { duration: 0 },
          },
          unmount: {
            opacity: 0,
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: 1,
            transition: { duration: 0.25, times: [0.4, 0, 0.2, 1] },
          },
          mount: {
            opacity: 1,
            position: 'relative',
            zIndex: 2,
            transition: { duration: 0.5, times: [0.4, 0, 0.2, 1] },
          },
        }}
      >
        {tabs.map(({ value, content }) => {
          const isActive = value === activeTab && isTabsActive;

          return (
            <TabPanel
              key={value}
              value={value}
              className={cn(
                'px-0 md:px-4 min-h-[330px] subpixel-antialiased',
                !isActive && 'h-full overflow-hidden',
                tabPanelClassName,
              )}
            >
              {React.cloneElement<TabPropsType>(
                content as React.DetailedReactHTMLElement<any, HTMLElement>,
                {
                  isActive,
                },
              )}
            </TabPanel>
          );
        })}
      </TabsBody>
    </MaterialTabs>
  );
};

export default React.memo(Tabs);
