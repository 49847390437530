import React from 'react';

import { Typography } from '@material-tailwind/react';
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api';

const center = { lat: 53.7098, lng: 27.9534 };

const MoneyMap = () => {
  return (
    <div className="mt-12">
      <Typography variant="h3" className="font-semibold text-center mb-3">
        Следи за картой и забери деньги
      </Typography>
      <div className="relative">
        <Typography variant="h2" className="absolute left-4 top-4 z-[999] text-[#fff]">
          150$
        </Typography>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}>
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: '350px',
            }}
            center={center}
            zoom={5}
            options={{
              disableDefaultUI: true,
              fullscreenControl: true,
              gestureHandling: 'greedy',
              mapTypeId: 'hybrid',
            }}
          >
            <Circle
              center={center}
              radius={170000}
              options={{
                strokeColor: '#aed581',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#aed581',
                fillOpacity: 0.35,
              }}
            />
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default React.memo(MoneyMap);
