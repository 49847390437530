import React from 'react';

import { Typography } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  className?: string;
};

const Title: React.FC<React.PropsWithChildren<PropsType>> = ({ children, className }) => {
  return (
    <Typography
      variant="h2"
      className={cn('text-[40px] 2xl:text-[50px]  font-semibold', className)}
      color="amber"
    >
      {children}
    </Typography>
  );
};

export default React.memo(Title);
