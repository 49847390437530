import React, { useEffect, useState } from 'react';

import { NumberInput } from '@components/common';
import { IHamsterCard, IHamsterCardResults } from '@interfaces/hamster-card';
import { Typography } from '@material-tailwind/react';
import cn from 'classnames';

import { hamsterCardsRenames } from '../../data/humster-combat-cards';
import { roundAmount } from '../../utils/amount';

type PropsType = {
  card: IHamsterCard;
  results: IHamsterCardResults;
  paybackColor?: string;
  onChange: (results: IHamsterCardResults) => void;
};

const calculatePayback = (price: string, income: string): string => {
  const result = parseFloat(price) / parseFloat(income) / 24;

  return roundAmount(result);
};

const HamsterCard: React.FC<PropsType> = ({ card, results = {}, paybackColor, onChange }) => {
  const [price, setPrice] = useState(results.price || '');
  const [income, setIncome] = useState(results.income || '');

  const renderPayback = () => {
    if (!price || !income) {
      return '-';
    }

    return calculatePayback(price, income);
  };

  useEffect(() => {
    const payback = price && income ? calculatePayback(price, income) : null;

    onChange({
      price,
      income,
      payback,
    });
  }, [price, income]);

  return (
    <div className="flex gap-3 px-0.5 py-1.5">
      <div className="w-[30%] flex items-center justify-center">
        <Typography variant="small" className="font-semibold text-center text-xs">
          {hamsterCardsRenames[card.name] || card.name}
        </Typography>
      </div>
      <div className="w-[20%] flex items-center justify-center">
        <NumberInput value={price} onChange={setPrice} placeholder="Цена" />
      </div>
      <div className="w-[25%] flex items-center justify-center">
        <NumberInput value={income} onChange={setIncome} placeholder="Прибыль в час" />
      </div>
      <div className="w-[25%] flex items-center justify-center">
        <Typography
          variant="small"
          className={cn('font-semibold text-center text-xs', paybackColor)}
        >
          {renderPayback()}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(HamsterCard);
