export default {
  BINANCE: 'https://accounts.binance.com/register?ref=FM0VMCD8',
  BYBIT: 'https://partner.bybit.com/b/maksbaks',
  OKX: 'https://www.okx.com/join/MAKSBAKS',
  BING_X: 'https://bingx.com/partner/maksbaks',
  MEXC: 'https://www.mexc.com/ru-RU/register?inviteCode=mexc-maksbaks',
  TELEGRAM_INVEST: 'https://t.me/baks_max',
  TELEGRAM_TRADING: 'https://t.me/mbaks_crypto',
  TELEGRAM_EDUCATION: 'https://t.me/mbaks_trading',
  TELEGRAM_SUPPORT: 'https://t.me/mbaks_manager',
  YOU_TUBE_MAIN: 'https://www.youtube.com/@MAKSBAKS',
  YOU_TUBE_SHORTS: 'https://www.youtube.com/@mbakscrypto',
  YOU_TUBE_EDUCATION: 'https://www.youtube.com/@MBaksTrading',
  INSTAGRAM: 'https://www.instagram.com/maksbaksmain/',
  VK: 'https://vk.com/max_baks_pab',
  TANGEM: 'https://tangem.com/pricing/?promocode=EJ5MQD',
  TREZOR: 'https://www.safepal.com/store/s1?ref=maximshaikout',
  SAFEPAL: 'https://store.safepal.com/safepal-s1-hardware-wallet.html?ref=maximshaikou',
  INSTAGRAM_AFRAME_SAMOED: 'https://www.instagram.com/aframe_samoed/',
};
