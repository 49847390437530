import React from 'react';

import binanceIcon from '@assets/binance.png';
import bingXIcon from '@assets/bingx.png';
import byBitIcon from '@assets/byBit.png';
import okxIcon from '@assets/okx.png';
import { IconLink } from '@components/common';
import links from '@config/links';
import { Typography } from '@material-tailwind/react';

interface IPartner {
  icon: any;
  title: string;
  link: string;
}

const partners: IPartner[] = [
  {
    icon: byBitIcon,
    title: 'ByBit',
    link: links.BYBIT,
  },
  {
    icon: bingXIcon,
    title: 'BingX',
    link: links.BING_X,
  },
  {
    icon: okxIcon,
    title: 'OKX',
    link: links.OKX,
  },
  {
    icon: binanceIcon,
    title: 'Binance',
    link: links.BINANCE,
  },
];

const PartnersTab = () => {
  return (
    <div>
      <Typography className="text-2xl lg:text-3xl font-semibold">С нами сотрудничают:</Typography>
      <div className="grid grid-cols-3 lg:flex mt-10 gap-3 lg:gap-10">
        {partners.map((partner) => (
          <IconLink
            key={partner.link}
            icon={partner.icon}
            title={partner.title}
            url={partner.link}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(PartnersTab);
