import React from 'react';

import { Typography } from '@material-tailwind/react';

const TargetTab = () => {
  return (
    <div className="lg:w-4/5">
      <Typography className="lg:text-2xl text-xl font-semibold">
        Публиковать полезный контент по трейдингу, инвестированию в криптовалюты и фондовый рынок.
        Показать как можно добиваться своих целей на личном примере
      </Typography>
    </div>
  );
};

export default React.memo(TargetTab);
