import React from 'react';

import tgMangerJpg from '@assets/telegram-manager.jpg';
import tgIcon from '@assets/telegram.png';
import { Title } from '@components/common';
import { Page } from '@components/layout';
import links from '@config/links';
import { Typography } from '@material-tailwind/react';

const ConnectionPage = () => {
  return (
    <Page>
      <div className="lg:flex justify-between gap-[50px] w-full">
        <div className="lg:mt-16 lg:w-[30%]">
          <Title>Связь</Title>
        </div>
        <div className="w-full pt-[80px]">
          <Typography variant="h3" className="text-2xl text-white font-medium text-center my-8">
            По вопросам сотрудничества и рекламы
          </Typography>
          <div className="flex justify-center">
            <a href={links.TELEGRAM_SUPPORT} target="_blank" rel="noreferrer">
              <div className="md:w-[400px] md:h-[400px] rounded-md overflow-hidden relative">
                <img src={tgMangerJpg} alt="Telegram Manager" className="w-full h-full" />
                <img
                  src={tgIcon}
                  alt="Telegram"
                  className="w-[30%] h-[30%] md:w-[105px] md:h-[105px] absolute z-50 left-0 top-0 bottom-0 transform translate-y-7 right-0 m-auto"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(ConnectionPage);
