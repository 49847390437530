import React from 'react';

import mabosScreenerJPG from '@assets/mabos-screener.jpg';
import { InfoCard } from '@components/common';

const Mabos = () => {
  return (
    <div>
      <div className="grid grid-cols-1 2xl:grid-cols-2 gap-6">
        <InfoCard
          title="Mabos Screener"
          description="Удобный инструмент для трейдера, который позволяет находить нужные ситуация для торговли быстрее и проще"
          icon={mabosScreenerJPG}
        />
      </div>
    </div>
  );
};

export default React.memo(Mabos);
