import { IHamsterCard } from '@interfaces/hamster-card';

export const marketsCards: IHamsterCard[] = [
  { name: 'Fun tokens' },
  { name: 'Staking' },
  { name: 'BTC pairs' },
  { name: 'ETH pairs' },
  { name: 'Top10 cmc' },
  { name: 'GameFi' },
  { name: 'Defi 2.0' },
  { name: 'SocialFi' },
  { name: 'Meme' },
  { name: 'Shit' },
  { name: 'x10' },
  { name: 'x20' },
  { name: 'x30' },
  { name: 'x50' },
  { name: 'x75' },
  { name: 'x100' },
  { name: 'Derivatives' },
  { name: 'Prediction' },
  { name: 'Web3' },
  { name: 'DAO' },
  { name: 'P2P' },
  { name: 'Trading bots' },
  { name: 'LayerZero Listing' },
];

export const prAndTeamCards: IHamsterCard[] = [
  { name: 'Support team' },
  { name: 'HamsterBook' },
  { name: 'X' },
  { name: 'Cointelegraph' },
  { name: 'HamsterTube' },
  { name: 'HamsterGram' },
  { name: 'TikTok' },
  { name: 'Coindesk' },
  { name: 'Influencers' },
  { name: 'CEO' },
  { name: 'IT team' },
  { name: 'Marketing' },
  { name: 'Partnership' },
  { name: 'Product team' },
  { name: 'BisDev team' },
  { name: 'Two factor' },
  { name: 'UX and UI' },
  { name: 'Security' },
  { name: 'QA team' },
  { name: 'Antihacking' },
  { name: 'Risk' },
  { name: 'Security Audition' },
  { name: 'Anonymous' },
  { name: 'Blocking' },
  { name: 'Tokenomics expert' },
  { name: 'Consensus' },
  { name: 'VC Labs' },
  { name: 'Compliance officer' },
  { name: 'Welcome to Ams' },
  { name: 'Development Hub' },
  { name: 'Data Center Tokyo' },
  { name: 'Leaderboards' },
  { name: 'Minigame' },
];

export const legalCards: IHamsterCard[] = [
  { name: 'KYC' },
  { name: 'KYB' },
  { name: 'Legal opinion' },
  { name: 'SEC' },
  { name: 'Anti money' },
  { name: 'Licence UAE' },
  { name: 'Licence Europe' },
  { name: 'Licence Asia' },
  { name: 'Licence South' },
  { name: 'Licence Australia' },
  { name: 'Licence North' },
  { name: 'Licence Nigeria' },
  { name: 'Licence Japan' },
  { name: 'Licence Ethiopia' },
  { name: 'Licence India' },
  { name: 'Licence Bangladesh' },
  { name: 'Licence Indonesia' },
  { name: 'Licence Vietnam' },
  { name: 'Licence Turkey' },
  { name: 'Licence Philippines' },
];

export const specialsCards: IHamsterCard[] = [
  { name: 'Business skills' },
  { name: 'Speaking to employees' },
  { name: 'Investments in farming' },
  { name: 'Collection of vintage cars' },
  { name: 'Launching a rocket to Mars' },
  { name: 'Electric car production' },
  { name: 'Speaking on a TV show' },
  { name: 'Negotiations with partners' },
  { name: 'Company development' },
  { name: 'Communication with' },
  { name: 'Hamster with friends' },
  { name: 'Hamster helps whales' },
  { name: 'Vipassana hamster' },
  { name: 'University hamster' },
  { name: 'HamsterGPT' },
  { name: 'Sleeping hamster' },
  { name: 'Healthy nutrition hamster' },
  { name: 'Gym hamster' },
  { name: 'TON listing on Binance' },
  { name: 'University hamster' },
  { name: 'Vipassana hamster' },
  { name: "Carry it like it's hot" },
  { name: 'Ethereum birthday' },
  { name: 'HamsterBank' },
  { name: 'Bitcoin Conference 2024' },
  { name: 'Telegram Stars Integration' },
  { name: 'Kamala is calling' },
  { name: 'Telegram Miniapp Launch' },
  { name: 'Hamster Analytics' },
  { name: 'Apple AppStore launch' },
  { name: 'Fight fight fight' },
  { name: '50M Telegram channel' },
  { name: 'Rolex for soulmate' },
  { name: 'Call for BTC to rise' },
  { name: 'Business jet' },
  { name: 'Яндекс плюс' },
  { name: 'CX Hub Istanbul' },
  { name: 'Winline' },
  { name: 'Hamsters break' },
  { name: 'Web3 Game Con' },
  { name: 'X Network 10 Million' },
  { name: 'Hamster Green' },
  { name: 'YouTube 25 Million' },
  { name: 'TG Leaders' },
  { name: 'Premarket Launch' },
  { name: 'Hamster Combat merch' },
  { name: 'TON + Hamster' },
  { name: 'Consensus Pass' },
  { name: 'Web3 academy' },
  { name: 'Youtube Gold' },
  { name: 'Hamster YouTube' },
  { name: 'Bitcoin Pizza Day' },
  { name: 'Top 10 Global' },
  { name: 'NFT Collection' },
  { name: 'Special Conference' },
  { name: 'Short squeeze' },
  { name: 'There are two' },
  { name: 'Long squeeze' },
  { name: 'Villa for the DEV' },
  { name: 'Apps Center' },
  { name: 'Bogdanoff' },
  { name: 'USDT on TON' },
];

export const web3Cards: IHamsterCard[] = [
  { name: 'DEX' },
  { name: 'Oracle' },
  { name: 'Vesting Smartcontracts' },
  { name: 'Launchpad' },
  { name: 'NFT Marketplace' },
  { name: 'Grand for Developers' },
  { name: 'NFT Metaverse' },
  { name: 'Crypto Farming' },
  { name: 'Sport Collectibles' },
  { name: 'Market Making' },
  { name: 'Web3 Advertising' },
];

export const hamsterCardsRenames: Record<string, string> = {
  'Apple AppStore launch': 'HamsterStore launch',
  'Rolex for soulmate': 'HamsterWatch for soulmate',
};
