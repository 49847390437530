import React, { useCallback } from 'react';

import { Header } from '@components/layout/index';
import Navigation from '@components/layout/Navigation';
import { MobileNav } from '@material-tailwind/react';

import { useLg } from '../../hooks/responsive/useLg';

const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isLg = useLg();
  const [openNav, setOpenNav] = React.useState(false);

  const toggleNav = useCallback(() => setOpenNav((prev) => !prev), []);

  return (
    <div className="container mx-auto 2xl:max-w-[2300px] px-4 md:px-0 2xl:px-[100px] grow flex flex-col relative pb-[100px]">
      <Header openNav={openNav} toggleNav={toggleNav} />
      <div>
        <MobileNav open={openNav}>
          <div className="mt-8">
            <Navigation />
          </div>
        </MobileNav>
      </div>
      <div className="pt-[30px] grow flex flex-col relative">{children}</div>
      {isLg && (
        <Navigation className="bottom-[30px] 3xl:bottom-[100px] z-[999] fixed lg:pl-1.5 xl:pl-0" />
      )}
    </div>
  );
};

export default React.memo(MainLayout);
