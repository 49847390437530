import React from 'react';

import tgIcon from '@assets/telegram.png';
import links from '@config/links';
import { IHamsterCardWithResults } from '@interfaces/hamster-card';
import { Button, Typography } from '@material-tailwind/react';

import { hamsterCardsRenames } from '../../data/humster-combat-cards';

type PropsType = {
  cards: IHamsterCardWithResults[];
};

const HamsterTopCards: React.FC<PropsType> = ({ cards }) => {
  const renderCard = (card: IHamsterCardWithResults) => {
    return (
      <div key={card.name} className="flex gap-3 px-0.5 py-1.5">
        <div className="w-[35%]">
          <Typography variant="small" className="font-semibold text-center text-xs">
            {hamsterCardsRenames[card.name] || card.name}
          </Typography>
        </div>
        <div className="w-[35%]">
          <Typography variant="small" className="font-semibold text-center text-xs">
            {card.category}
          </Typography>
        </div>
        <div className="w-[30%]">
          <Typography variant="small" className="font-semibold text-center text-xs text-green-500">
            {card.payback}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div className="md:mt-10 2xl:mt-2 max-w-md">
      <Typography color="amber" variant="h5" className="mb-9 text-center">
        Топ по окупаемости
      </Typography>
      <div>
        <div className="flex gap-3 border-b border-amber-500 px-0.5 py-1.5">
          <div className="w-[35%]">
            <Typography variant="small" className="font-semibold text-center text-xs">
              Карточка
            </Typography>
          </div>
          <div className="w-[35%]">
            <Typography variant="small" className="font-semibold text-center text-xs">
              Категория
            </Typography>
          </div>
          <div className="w-[30%]">
            <Typography variant="small" className="font-semibold text-center text-xs">
              Окупаемость, дней
            </Typography>
          </div>
        </div>
        {cards.map(renderCard)}
      </div>
      <div className="flex gap-3 items-center mt-10 justify-center">
        <a href={links.TELEGRAM_INVEST} target="_blank" rel="noreferrer">
          <Button className="flex gap-3 items-center whitespace-nowrap">
            Подпишись на наш телеграм
            <img src={tgIcon} alt="Telegram" className="w-[20px]" />
          </Button>
        </a>
      </div>
    </div>
  );
};

export default React.memo(HamsterTopCards);
