import React, { useState } from 'react';

import CardTabs, { ICardTab } from '@components/cards/CardTabs';
import cn from 'classnames';

type PropsType = {
  title: React.ReactNode;
  tabs?: ICardTab[];
};

const CardsPage: React.FC<PropsType> = ({ title, tabs }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  return (
    <div className="flex flex-col lg:flex-row grow">
      <div className="lg:w-[30%] mb-4 lg:mb-0 lg:flex lg:flex-col lg:justify-between">
        <div
          className={cn(
            'lg:pt-[80px] lg:pr-8 lg:pb-0 transition-all ease-in-out duration-500',
            isScrolled &&
              'lg:pb-5 lg:scale-50 !lg:pt-0 !lg:pr-0 lg:-translate-y-full origin-bottom-left',
          )}
        >
          {title}
        </div>
      </div>
      <CardTabs className="lg:w-[70%]" onScrolled={setIsScrolled} tabs={tabs} />
    </div>
  );
};

export default React.memo(CardsPage);
