import React from 'react';

import Tabs, { ITab } from '@components/common/Tabs';
import HamsterCardsList from '@components/hamster-combat/HamsterCardsList';
import { IHamsterCardResults } from '@interfaces/hamster-card';

import {
  legalCards,
  marketsCards,
  prAndTeamCards,
  specialsCards,
  web3Cards,
} from '../../data/humster-combat-cards';

type PropsType = {
  results: Record<string, IHamsterCardResults>;
  onChange: (name: string, results: IHamsterCardResults) => void;
};

enum CARD_CATEGORIES {
  MARKETS = 'MARKETS',
  PR_AND_TEAM = 'PR_AND_TEAM',
  LEGAL = 'LEGAL',
  SPECIALS = 'SPECIALS',
  WEB_3 = 'WEB_3',
}

const HamsterCards: React.FC<PropsType> = ({ results, onChange }) => {
  const tabs: ITab[] = [
    {
      label: 'PR&Team',
      value: CARD_CATEGORIES.PR_AND_TEAM,
      content: <HamsterCardsList cards={prAndTeamCards} results={results} onChange={onChange} />,
    },
    {
      label: 'Markets',
      value: CARD_CATEGORIES.MARKETS,
      content: <HamsterCardsList cards={marketsCards} results={results} onChange={onChange} />,
    },
    {
      label: 'Legal',
      value: CARD_CATEGORIES.LEGAL,
      content: <HamsterCardsList cards={legalCards} results={results} onChange={onChange} />,
    },
    {
      label: 'Web3',
      value: CARD_CATEGORIES.WEB_3,
      content: <HamsterCardsList cards={web3Cards} results={results} onChange={onChange} />,
    },
    {
      label: 'Specials',
      value: CARD_CATEGORIES.SPECIALS,
      content: <HamsterCardsList cards={specialsCards} results={results} onChange={onChange} />,
    },
  ];

  return (
    <div>
      <Tabs
        tabClassName="text-sm sm:!text-lg md:!text-xl 3xl:!text-2xl mr-0 md:mr-0 px-1.5 sm:px-2 md:px-4"
        tabPanelClassName="pt-0"
        bodyClassName="!mt-4"
        tabs={tabs}
        defaultTab={CARD_CATEGORIES.PR_AND_TEAM}
      />
    </div>
  );
};

export default React.memo(HamsterCards);
