import React from 'react';

import routes from '@config/routes';
import { Typography } from '@material-tailwind/react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

type PropsType = {
  className?: string;
};

interface ILink {
  title: string;
  url: string;
}

const links: ILink[] = [
  {
    title: 'Главная',
    url: routes.HOME,
  },
  {
    title: 'О нас',
    url: routes.ABOUT_US,
  },
  {
    title: 'Связь',
    url: routes.CONNECTION,
  },
  {
    title: 'Вакансии',
    url: routes.VACANCIES,
  },
  {
    title: 'Hamster Combat',
    url: routes.HAMSTER_COMBAT,
  },
];

const Navigation: React.FC<PropsType> = ({ className }) => {
  return (
    <ul className={className}>
      {links.map((l) => (
        <li key={l.url}>
          <NavLink to={l.url}>
            {({ isActive }) => (
              <Typography
                color="white"
                className={cn(
                  'font-semibold hover:text-[#fff] transition-colors delay-50 ',
                  isActive && '!text-amber-500',
                )}
              >
                {l.title}
              </Typography>
            )}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default React.memo(Navigation);
