import React from 'react';

import { Typography } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  title: string;
  icon: string;
  className?: string;
};

const MediaTabButton: React.FC<PropsType> = ({ title, icon, className }) => {
  return (
    <div className={cn('flex gap-1 md:gap-3 items-center justify-center', className)}>
      <img className="w-[25px] h-[25px] md:w-[30px] md:h-[30px] rounded-full" src={icon} alt={title} />
      <Typography className="font-semibold text-xs md:text-lg sm:whitespace-nowrap">{title}</Typography>
    </div>
  );
};

export default React.memo(MediaTabButton);
