import React from 'react';

import { Title } from '@components/common';
import { Page } from '@components/layout';
import { Typography } from '@material-tailwind/react';

const VacanciesPage = () => {
  return (
    <Page>
      <div className="lg:flex justify-between gap-[50px] w-full">
        <div className="lg:mt-16 lg:w-[30% o]">
          <Title>Мы ищем специалиста</Title>
        </div>
        <div className="w-full pt-[80px]">
          <Typography className="mt-8 text-2xl text-white font-medium text-center">
            На данный момент вакансий нет
          </Typography>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(VacanciesPage);
