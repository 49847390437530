import React from 'react';

import { Page } from '@components/layout';
import routes from '@config/routes';
import { Button, Typography } from '@material-tailwind/react';
import { NavLink } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Page>
      <div className="grow flex flex-col items-center justify-center px-4 md:px-0 lg:pt-[100px]">
        <Typography className="text-white text-center" variant="h2" color="white">
          Извините, страница не найдена!
        </Typography>
        <Typography
          className="text-white max-w-2xl text-center mt-4 font-medium"
          variant="paragraph"
          color="gray"
        >
          К сожалению, мы не смогли найти страницу, которую вы ищете. Возможно, вы ошиблись в
          URL-адресе? Обязательно проверьте орфографию.
        </Typography>
        <NavLink to={routes.HOME}>
          <Button className="mt-6">Главная</Button>
        </NavLink>
      </div>
    </Page>
  );
};

export default React.memo(NotFoundPage);
