import React, { useMemo, useState } from 'react';

import { Title } from '@components/common';
import {
  HamsterCards,
  HamsterListing,
  HamsterTopCards,
  MoneyMap,
} from '@components/hamster-combat';
import { Page } from '@components/layout';
import {
  IHamsterCard,
  IHamsterCardResults,
  IHamsterCardWithResults,
} from '@interfaces/hamster-card';
import { debounce } from 'lodash';

import {
  legalCards,
  marketsCards,
  prAndTeamCards,
  specialsCards,
  web3Cards,
} from '../../data/humster-combat-cards';
import ls from '../../utils/ls';

const LS_KEY = 'hamster-card';

const categories: Record<string, IHamsterCard[]> = {
  Markets: marketsCards,
  'PR&Team': prAndTeamCards,
  Legal: legalCards,
  Specials: specialsCards,
  Web3: web3Cards,
};

const HamsterCombat = () => {
  const [results, setResults] = useState<Record<string, IHamsterCardResults>>(ls.get(LS_KEY) || {});

  const handleChange = debounce((name: string, result: IHamsterCardResults) => {
    setResults((prev) => {
      const data = {
        ...prev,
        [name]: result,
      };

      ls.set(LS_KEY, data);

      return data;
    });
  }, 500);

  const topCards = useMemo<IHamsterCardWithResults[]>(() => {
    const cards = Object.entries(results)
      .map(([name, result]) => {
        const category = Object.keys(categories).find((c) =>
          categories[c].some((card) => card.name === name),
        );

        if (!category) {
          return null;
        }

        return {
          name,
          category,
          ...result,
        } as IHamsterCardResults;
      })
      .filter((card) => !!card?.payback) as (Omit<IHamsterCardWithResults, 'payback'> & {
      payback: string;
    })[];

    cards.sort((a, b) => parseFloat(a.payback) - parseFloat(b.payback));

    return cards.slice(0, 5);
  }, [results]);

  return (
    <Page>
      <div className="lg:flex items-end 3xl:items-start flex-col 3xl:flex-row gap-[20px] w-full 3xl:justify-between lg:mt-[-150px] 3xl:mt-0">
        <div className="lg:mt-16 lg:w-[75%] 3xl:w-[25%]">
          <Title>Калькулятор выгодной прокачки</Title>
        </div>
        <div className="w-full lg:w-[75%] pt-[60px] lg:pt-0">
          <div className="flex flex-col 2xl:flex-row gap-[50px]">
            <div className="2xl:w-[60%]">
              <HamsterCards results={results} onChange={handleChange} />
            </div>
            <div className="2xl:w-[40%]">
              <HamsterTopCards cards={topCards} />
              <HamsterListing />
              <MoneyMap />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(HamsterCombat);
