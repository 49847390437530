import React from 'react';

type PropsType = {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

const NumberInput: React.FC<PropsType> = ({ onChange, value, placeholder }) => {
  const handleChange = (e: React.BaseSyntheticEvent) => {
    const pattern = /^(\d*)([.,]?)(\d*)$/;

    if (pattern.test(e.target.value)) {
      const value = e.target.value.replace(',', '.');

      onChange(value);
    }
  };

  return (
    <input
      type="text"
      className="w-full bg-transparent border border-amber-500 rounded-md outline-none text-white font-normal text-sm px-2 py-0.5 placeholder:text-xs placeholder:text-gray-300"
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};

export default React.memo(NumberInput);
