import React from 'react';

import Logo from '@components/layout/Logo';
import { IconButton } from '@material-tailwind/react';
import { BurgerIcon, CloseIcon } from 'components/icons';

type PropsType = {
  toggleNav: () => void;
  openNav: boolean;
};

const Header: React.FC<PropsType> = ({ toggleNav, openNav }) => {
  return (
    <header className="flex justify-between py-6 lg:py-10 items-center">
      <Logo />
      <div className="flex items-center gap-10">
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-white text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={toggleNav}
        >
          {openNav ? <CloseIcon /> : <BurgerIcon />}
        </IconButton>
      </div>
    </header>
  );
};

export default React.memo(Header);
