import React from 'react';

import { Routes } from '@components/core';

const App = () => (
  <div className="min-h-[100vh] overflow-x-hidden md:overflow-x-visible from-gray-800 to-blue-gray-800 bg-gradient-to-tr flex flex-col ">
    <Routes />
  </div>
);

export default App;
